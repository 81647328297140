
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/ui-components/PageTitle/PageTitle.vue';
import AdSpacesPlaylist from '@/pages/store-owner/Playlist/components/AdSpacesPlaylist/AdSpacesPlaylist.vue';

@Component({
  name: 'Playlist',
  components: { AdSpacesPlaylist, PageTitle },
})
export default class Playlist extends Vue {
}
