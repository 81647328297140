
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { LocationData } from '@/types/locations';
import { LoadLocations } from '@/store/modules/common-module/types';
import { AuthorizedUser, UserInfo } from '@/types/users';
import AdSpaceSelectItem from '@/pages/store-owner/Playlist/components/AdSpacesPlaylist/AdSpaceSelectItem.vue';
import DatePickerInput from '@/ui-components/DatePickerInput/DatePickerInput.vue';
import { Campaign, PlaylistCampaign } from '@/types/campaigns';
import {
  getAdSpacePlaylist,
} from '@/actions/campaigns/actions';
import PlaylistTable from '@/pages/store-owner/Playlist/components/AdSpacesPlaylist/PlaylistTable.vue';
import { Nullable } from '@/types/misc';
import SkeletonLoader from '@/ui-components/SkeletonLoader/SkeletonLoader.vue';
import { getUsersCompanyName } from '@/actions/users/actions';
import { convertCampaignTimestampsToDates, isAdvertiserCampaign } from '@/utils/campaigns';

@Component({
  name: "AdSpacesPlaylist",
  components: { PlaylistTable, AdSpaceSelectItem, DatePickerInput, SkeletonLoader },
})
export default class AdSpacesPlaylist extends Vue {
  storeOwnerAdSpaces: LocationData[] = [];
  selectedAdSpace: Nullable<LocationData> = null;
  loadingLocations: boolean = false;
  calendarIcon = '/assets/icons/calendar.svg';
  selectedDate = '';
  loadingPlaylist: boolean = false;
  sortPlaylistByFrequency: boolean = false;
  playlistData: Array<PlaylistCampaign & { companyName?: UserInfo['COMPANY_NAME']}> = [];


  @Getter('locations', { namespace: namespaces.CommonModule })
  public locations!: LocationData[];

  @Getter('userInfo', { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  @Action("loadStoreOwnerLocations", { namespace: namespaces.CommonModule })
  public loadStoreOwnerLocations!: LoadLocations;

  async created() {
    this.loadingLocations = true;
    this.storeOwnerAdSpaces = await this.loadStoreOwnerLocations();
    this.loadingLocations = false;
  }

  async loadPlaylistData() {
    try {
      this.loadingPlaylist = true;
      this.playlistData = await getAdSpacePlaylist(
        {
          adSpaceId: (this.selectedAdSpace as LocationData).ID,
          selectedDate: this.selectedDate,
          sortByFrequency: this.sortPlaylistByFrequency
        });
      const usersCompanyMap = await getUsersCompanyName(this.playlistData)

      this.playlistData = this.playlistData.map(playlistCampaign => {
        const campaign = convertCampaignTimestampsToDates(playlistCampaign);
        if (isAdvertiserCampaign(campaign)) {
          return {
            ...campaign,
            advertiserName: usersCompanyMap[campaign.ADVERTISER_UID]
          }
        }

        return campaign;
      })
    } finally {
      this.loadingPlaylist = false;
    }
  }

  @Watch('selectedDate')
  onDateChanged() {
    if (this.selectedAdSpace) {
      this.loadPlaylistData();
    }
  }

  @Watch('selectedAdSpace')
  onAdSpaceChange() {
    if (this.selectedDate) {
      this.loadPlaylistData();
    }
  }

  @Watch('sortPlaylistByFrequency')
  sortPlaylistByFrequencyChanged() {
    this.loadPlaylistData();
  }
}
